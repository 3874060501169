<template>
    <div>
      <v-card :loading="loading">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        >
        <v-icon
          class="mx-2"
          color="white"
          :disabled="loading"
          @click="openTab"
        >
          {{icons.openInNew}}
        </v-icon>
        <span class="text-body-2">{{name}}</span>
    
          <v-spacer></v-spacer>
        <v-btn 
          icon
          @click="close"
        > 
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
        </v-toolbar>
        <div  class="pa-2"> 
        <v-card-text class="blue-grey lighten-5  overflow-y-auto"   style="max-height: 1000px">
          <video preload  muted id="video-view"  style="width:100% ;" :src="videoUrl"   controls controlslist="nodownload" disablepictureinpicture playsinline >
            <p>ご使用のブラウザでは動画再生に対応していません</p>
          </video>
        </v-card-text>
        </div>
      
      </v-card>
    
      </div>
      

</template> 

<script>
import { mdiClose, mdiOpenInNew } from '@mdi/js';


export default {
  name: 'DialogVideoView',
  props:['url','loading',"name"],
  data () {
    return {
      icons:{
        "close":mdiClose,
        "openInNew":mdiOpenInNew
      },
      videoUrl:null,
    }
  },

  watch: {
    url: function(){
      // console.log(this.url)
      this.videoUrl = this.url;
    }

  },


  methods: {

    close (){
      let v = document.getElementById("video-view");
      v.pause();
      v.currentTime = 0;
      this.videoUrl = null,
      this.$emit('closeEmit')
    },

    openTab(){
      window.open(this.url);
    }
 
  },
}

</script>
